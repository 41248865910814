@import url('https://fonts.googleapis.com/css2?family=Alice&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Imperial+Script&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Qwitcher+Grypen:wght@700&display=swap');

/* ///////////////////////////////////////////// Section 1 ////////////////////////////////////////////////// */

.Accommodation-banner {
    background: linear-gradient(to right, rgb(0, 0, 0, .5), rgb(0, 0, 0, .6)), url("../greenHillVillas-webp/banner2.webp");
    height: var(---bannerHeight);
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

.Accommodation-banner-text {
    position: relative;
    height: 80vh;
    justify-content: center;
    display: flex;
    align-items: center;
}

.Accommodation-banner-text h2 {
    font-size: 50px;
    font-family: 'Lora', serif;
    font-weight: bold;
    color: white;
    margin: 0px;
    padding: 0px;

}
.Accommodation-banner-text h1 {
    font-size: 50px;
    font-family: 'Lora', serif;
    font-weight: bold;
    color: white;
    margin: 0px;
    padding: 0px;

}

.Text {
    position: absolute;
    left: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    clip-path: polygon(0 0, 100% 100%, 0% 100%);
}

.AS1-Text {
    width: 100%;
    text-align: left;
    margin-left: 20vh;
}

.AS1-Text p {
    /* color: white; */
    font-size: 25px;
    font-family: 'Playfair Display', serif;
    color: white;
}


/* \\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\ */

.Room-titile {
    margin: 50px;
    position: relative;
}

.Room-titile h1 {
    text-transform: uppercase;
    font-family: 'Nuosu SIL', serif;
    font-weight: 500;
    font-size: 37px;
}

.Room-titile h2 {
    position: absolute;
    top: -50px;
    left: 0;
    right: -15px;
    font-family: 'Qwitcher Grypen', cursive;
    font-size: 85px;
    color: lightgrey;
    z-index: -1;

}





/* ///////////////////////////////////////////// Section 2 ////////////////////////////////////////////////// */


.AccommSection2 {
    /* border: 1px solid blue; */
    padding: 0px 20px 50px 20px;
}

.Accomo-rooms-1 {
    /* border: 1px solid black; */
    position: relative;
}

.Accomo-rooms-Image1 {
    position: relative;
    /* border: 1px solid red; */
    margin: 25px 0px 10px 10px;
    height: 87vh;
}

.Accomo-rooms-Image1 img {
    height: 100%;
    width: 100%;
}

.Accomo-rooms-overlay-con {
    border: 10px solid #ffffff;
    position: absolute;
    top: 0;
    left: 0;
    height: 40vh;
    width: 45vh;
    background-color: white;
}

.Accomo-rooms-overlay-con hr {
    margin: 10px;
}

.Accomo-rooms-overlay-con h4 {
    /* border: 1px solid blue; */
    text-align: left;
    font-family: 'Alice', serif;
    font-size: 25px;
    font-weight: 600;
}

.Accomo-rooms-overlay-con p {
    /* border: 1px solid brown; */
    text-align: left;
    margin: 0px;
    padding: 0px;
    font-family: var(---paraFont);
    font-size: 15px;
}

.Room_price {
    display: flex;
    justify-content: left;
    align-items: center;
    text-align: center;
    /* border: 1px solid pink; */
}

.Room_price h6 {
    /* border: 1px solid gray; */
    margin: 0px;
    padding: 0px;
    font-family: 'Alice', serif;
    font-size: 17px;
    font-weight: 500;
}

.Room_price p {
    /* border: 1px solid green; */
    margin: 0px;
    padding: 0px;
    margin-left: 15px;
    font-family: 'Imperial Script', cursive;
    font-size: 17px;
    font-weight: 600;
}

/* right room  */

.Accomo-right-con-1 {
    /* border: 1px solid black; */
}

.Accomo-left-rooms-Image1 {
    /* border: 1px solid blue; */
    margin: 25px 10px 0px 5px;
    height: 270px;
    position: relative;
}

.Accomo-left-rooms-Image1 img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.Accomo-left-room-facility {
    /* border: 1px solid purple; */
    height: 25vh;
    width: 45vh;
    position: absolute;
    bottom: 0;
    right: 0;
    background-color: white;
    padding: 10px;
}

.Accomo-left-room-facility h4 {
    /* border: 1px solid blue; */
    /* text-align: left; */
    font-family: 'Alice', serif;
    font-size: 25px;
    font-weight: 600;
}

.Accomo-left-room-facility span {
    /* border: 1px solid red; */
    padding: 5px;
}

.Accomo-left-room-facility span svg {
    font-size: 28px;
    margin: 8px;
}


.Accomo-right-con-2 {}

.Accomo-left-rooms-Image2 {
    margin: 25px 10px 0px 5px;
    height: 418px;;
    /* height: 255px; */
    position: relative;
}

.Accomo-left-rooms-Image2 img {
    /* height: 100%; */
    height: 50vh;
    width: 100%;
    object-fit: cover;
}

.Accomo-left-room-facility2 {
    /* border: 1px solid purple; */
    height: 25vh;
    width: 45vh;
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: white;
    padding: 15px;
}


.Accomo-left-room-facility2 p {
    font-size: 16px;
    text-align: left;
    font-family: var(---paraFont);
}

/* //////////////  Room 2 Details ///////////////// */

.Accomo-left-Couple-room-facility {
    height: 25vh;
    width: 45vh;
    position: absolute;
    top: 0;
    left: 0;
    background-color: white;
    padding: 10px;
}

.Accomo-left-Couple-room-facility h4 {
    /* border: 1px solid blue; */
    /* text-align: left; */
    font-family: 'Alice', serif;
    font-size: 25px;
    font-weight: 600;
}

.Accomo-left-Couple-room-facility span {
    /* border: 1px solid red; */
    padding: 5px;
}

.Accomo-left-Couple-room-facility span svg {
    font-size: 28px;
    margin: 8px;
}

.Accomo-left-Couple-room-facility2 {
    height: 25vh;
    width: 45vh;
    position: absolute;
    bottom: 0;
    right: 0;
    background-color: white;
    padding: 10px;
}

.Accomo-left-Couple-room-facility2 p {
    font-size: 16px;
    text-align: left;
    font-family: var(---paraFont);
}


/* //////////////  Room 3 Details ///////////////// */

.Accomo-Family-rooms-overlay-con {
    border: 10px solid #ffffff;
    position: absolute;
    bottom: 0;
    right: 0;
    height: 51vh;
    width: 45vh;
    background-color: white;
    padding: 25px;
}

.Accomo-Family-rooms-overlay-con hr {
    margin: 10px;
}

.Accomo-Family-rooms-overlay-con h4 {
    /* border: 1px solid blue; */
    text-align: left;
    font-family: 'Alice', serif;
    font-size: 25px;
    font-weight: 600;
}

.Accomo-Family-rooms-overlay-con p {
    /* border: 1px solid brown; */
    text-align: left;
    margin: 0px;
    padding: 0px;
    font-family: var(---paraFont);
    font-size: 15px;
}


.Room_price {
    display: flex;
    justify-content: left;
    align-items: center;
    text-align: center;
    /* border: 1px solid pink; */
}

.Room_price h6 {
    /* border: 1px solid gray; */
    margin: 0px;
    padding: 0px;
    font-family: 'Alice', serif;
    font-size: 17px;
    font-weight: 500;
}

.Room_price p {
    /* border: 1px solid green; */
    margin: 0px;
    padding: 0px;
    margin-left: 15px;
    font-family: 'Imperial Script', cursive;
    font-size: 17px;
    font-weight: 600;
}





.Accomo-left-Family-room-facility {
    height: 25vh;
    width: 45vh;
    position: absolute;
    top: 0;
    right: 0;
    background-color: white;
    padding: 10px;
}

.Accomo-left-Family-room-facility h4 {
    /* border: 1px solid blue; */
    /* text-align: left; */
    font-family: 'Alice', serif;
    font-size: 25px;
    font-weight: 600;
}

.Accomo-left-Family-room-facility span {
    /* border: 1px solid red; */
    padding: 5px;
}

.Accomo-left-Family-room-facility span svg {
    font-size: 28px;
    margin: 8px;
}

.Accomo-left-Family-room-facility2 {
    height: 25vh;
    width: 45vh;
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: white;
    padding: 10px;
}

.Accomo-left-Family-room-facility2 p {
    font-size: 16px;
    text-align: left;
    font-family: var(---paraFont);
}


.Accomo-left-Family-room-facility2 {
    /* border: 1px solid purple; */
    height: 25vh;
    width: 45vh;
    position: absolute;
    top: 0;
    right: 0;
    background-color: white;
    padding: 15px;
}


.Accomo-left-Family-room-facility2 p {
    font-size: 16px;
    text-align: left;
    font-family: var(---paraFont);
}


/* //////////////  Room 4 Details ///////////////// */



.Accomo-Luxury-rooms-overlay-con {}


.Accomo-Luxury-rooms-overlay-con {
    border: 10px solid #ffffff;
    position: absolute;
    bottom: 0;
    right: 0;
    height: 30vh;
    width: 45vh;
    background-color: white;
    padding: 25px;
}

.Accomo-Luxury-rooms-overlay-con hr {
    margin: 10px;
}

.Accomo-Luxury-rooms-overlay-con h4 {
    /* border: 1px solid blue; */
    text-align: left;
    font-family: 'Alice', serif;
    font-size: 25px;
    font-weight: 600;
}

.Accomo-Luxury-rooms-overlay-con p {
    /* border: 1px solid brown; */
    text-align: left;
    margin: 0px;
    padding: 0px;
    font-family: var(---paraFont);
    font-size: 15px;
}


.Room_price {
    display: flex;
    justify-content: left;
    align-items: center;
    text-align: center;
    /* border: 1px solid pink; */
}

.Room_price h6 {
    /* border: 1px solid gray; */
    margin: 0px;
    padding: 0px;
    font-family: 'Alice', serif;
    font-size: 17px;
    font-weight: 500;
}

.Room_price p {
    /* border: 1px solid green; */
    margin: 0px;
    padding: 0px;
    margin-left: 15px;
    font-family: 'Imperial Script', cursive;
    font-size: 17px;
    font-weight: 600;
}

.Accomo-left-Luxury-room-facility {
    height: 25vh;
    width: 45vh;
    position: absolute;
    bottom: 0;
    right: 0;
    background-color: white;
    padding: 10px;
}

.Accomo-left-Luxury-room-facility h4 {
    /* border: 1px solid blue; */
    /* text-align: left; */
    font-family: 'Alice', serif;
    font-size: 25px;
    font-weight: 600;
}

.Accomo-left-Luxury-room-facility span {
    /* border: 1px solid red; */
    padding: 5px;
}

.Accomo-left-Luxury-room-facility span svg {
    font-size: 28px;
    margin: 8px;
}

.Accomo-left-Luxury-room-facility2 {
    height: 25vh;
    width: 45vh;
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: white;
    padding: 10px;
}

.Accomo-left-Luxury-room-facility2 p {
    font-size: 16px;
    text-align: left;
    font-family: var(---paraFont);
}


.Accomo-left-Luxury-room-facility2 {
    /* border: 1px solid purple; */
    height: 25vh;
    width: 45vh;
    position: absolute;
    bottom: 0;
    right: 0;
    background-color: white;
    padding: 15px;
}


.Accomo-left-Luxury-room-facility2 p {
    font-size: 16px;
    text-align: left;
    font-family: var(---paraFont);
}



.AccommSection3 {
    /* border: 1px solid blue; */
    padding: 50px 20px 50px 20px;
}

.AccommSection4 {
    /* border: 1px solid blue; */
    padding: 50px 20px 50px 20px;
}

.AccommSection5 {
    /* border: 1px solid blue; */
    padding: 50px 20px 50px 20px;
}

@media(max-width:600px) {
    .Accommodation-banner {
        background: linear-gradient(to right, rgb(0, 0, 0, .5), rgb(0, 0, 0, .6)), url("../greenHillVillas-webp//banner2.webp");
        height: 100vh;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
    }

    .AS1-Text {
        margin: 30px;
    }

    .Accommodation-banner-text h2 {
        font-size: 30px;
        color: white;
    }
    .Accommodation-banner-text h1 {
        font-size: 30px;
        color: white;
    }
    .AS1-Text p {
        font-size: 15px;
        font-family: 'Courgette', cursive;
        color: white;
    }

    .Accomo-left-rooms-Image1 img {
        display: none;
    }

    .Accomo-left-rooms-Image1 {
        /* border: 1px solid blue; */
        margin: 0px;
        height: auto;
        position: unset;
    }

    .Accomo-left-room-facility {
        height: auto !important;
        width: auto;
        position: unset;
        background-color: white;
        padding: 10px;
        border: 5px solid rgb(222, 222, 222);
        margin: 10px;
        padding: 10px;
    }

    .Accomo-left-Couple-room-facility {
        height: auto !important;
        width: auto;
        position: unset;
        background-color: white;
        padding: 10px;
        border: 5px solid rgb(222, 222, 222);
        margin: 10px;
        padding: 10px;
    }

    .Accomo-left-Luxury-room-facility {
        height: auto !important;
        width: auto;
        position: unset;
        background-color: white;
        padding: 10px;
        border: 5px solid rgb(222, 222, 222);
        margin: 10px;
        padding: 10px;
    }

    .Accomo-left-Family-room-facility {
        height: auto !important;
        width: auto;
        position: unset;
        background-color: white;
        padding: 10px;
        border: 5px solid rgb(222, 222, 222);
        margin: 10px;
        padding: 10px;
    }

    .Accomo-left-rooms-Image2 {
        margin: 0px;
        height: auto;
        margin: 10px;
        padding: 2px;
        border: 5px solid gainsboro;
        /* position: unset;  */
    }

    .Accomo-left-room-facility2 {
        height: auto;
        width: auto;
        position: unset;
    }

    .Accomo-left-Couple-room-facility2 {
        height: auto;
        width: auto;
        position: unset;
    }

    .Accomo-left-Family-room-facility2 {
        height: auto;
        width: auto;
        position: unset;
    }

    .Accomo-left-Luxury-room-facility2 {
        height: auto;
        width: auto;
        position: unset;
    }

    .Accomo-rooms-Image1 {
        margin: 10px;
    }

    .Accommodation-banner {
        height: 60vh;
    }

    .Accommodation-banner-text {
        height: 45vh;
    }

    .Accomo-rooms-Image1 {
        height: 60vh;
    }

    .Accomo-Luxury-rooms-overlay-con {
        top: 0;
        left: 0;
    }

    .AccommSection2 {
        padding: 0px;
    }

    .AccommSection3 {
        padding: 0px;
    }

    .AccommSection4 {
        padding: 0px;
    }

    .AccommSection5 {
        padding: 0px;
    }

}
.AccommSection4{
    background-color: var(---primeColor);

}
.AccommSection3{
    background-color: var(---primeColor);
}
.divider{
    background-color: var(---primeColor);

}
.villa-title{
    font-family: 'Alice', serif;
    font-weight: 600;
}
.villa-para{
    font-size: 16px;
    text-align: left;
    font-family: var(---paraFont);
}