/*--------------------------------------------------------------
# Footer
--------------------------------------------------------------*/

#footer {
    text-decoration: none;
    overflow-x: hidden;
    font-size: 14px;
    background: #1c1c1c;
}

#footer .footer-top {
    padding: 30px 0 30px 0;
}

#footer .footer-top .footer-contact {
    margin-bottom: 30px;
}

#footer .footer-top .footer-contact img {
    width: 20vh;
}

#footer .footer-top .footer-contact p {
    font-size: 14px;
    margin-bottom: 0;
    font-family: "Jost", sans-serif;
    color: var(---secondColor);
}

#footer .footer-top .footer-contact a {
    text-decoration: none;
}

#footer .footer-top h4 {
    text-align: left;
    font-size: 16px;
    font-weight: bold;
    color: var(---TheamGoldColor);
    position: relative;
    padding-bottom: 12px;
}

#footer .footer-top .footer-links {
    margin-bottom: 30px;
}

#footer .footer-top .footer-links ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

#footer .footer-top .footer-links ul i {
    padding-right: 2px;
    color: var(---primeColor);
    font-size: 18px;
    line-height: 1;
}

#footer .footer-top .footer-links ul li {
    padding: 10px 0;
    display: flex;
    align-items: center;
    transition: 0.4s ease-in-out;
}

#footer .footer-top .footer-links ul li:hover a {
    text-decoration: none;
}

#footer .footer-top .footer-links ul li:hover {
    transform: translateX(20px);
}

#footer .footer-top .footer-links ul li:first-child {
    padding-top: 0;
}

#footer .footer-top .footer-links ul a {
    color: #fff;
    transition: 0.3s;
    display: inline-block;
    line-height: 1;
    text-decoration: none;
}

#footer .footer-top .footer-links ul a:hover {
    text-decoration: none;
}

#footer .footer-top .social-links a {
    font-size: 20px;
    display: inline-block;
    /* background: var(---primeColor); */
    color: #faf6f6;
    line-height: 1;
    padding: 8px 0;
    margin-right: 7px;
    border-radius: 5px;
    text-align: center;
    width: 36px;
    height: 36px;
    transition: 0.5s ease-in-out;
}

.social-links a svg{
    color: white;
}

#footer .footer-bottom {
    padding-top: 15px;
    padding-bottom: 15px;
    color: #fff;
}

#footer .copyright {
    float: left;
}

#footer .credits {
    float: right;
    font-size: 13px;
}

#footer .credits a {
    transition: 0.3s;
    text-decoration: none;
}

@media (max-width: 768px) {
    #footer .footer-bottom {
        padding-top: 20px;
        padding-bottom: 20px;
    }
    #footer .copyright,
    #footer .credits {
        text-align: center;
        float: none;
    }
    #footer .credits {
        padding-top: 4px;
    }
}

@media(max-width:430px){
    #footer .footer-top .footer-links ul li {
        justify-content: center;
    }
}

