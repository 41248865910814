html,
body {
    overflow-x: hidden;
    margin: 0;
    padding: 0;
}

@import url('https://fonts.googleapis.com/css2?family=Nuosu+SIL&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Dancing+Script&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nuosu+SIL&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Libre+Baskerville&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display&display=swap');


/* ///////////////////////////////////////////// Section 1 ////////////////////////////////////////////////// */

.Home-banner {
    background: linear-gradient(to right, rgb(0, 0, 0, .4), rgb(0, 0, 0, .5)), url("../4BHvilla-webp/banner.webp");
    /* border: 1px solid red; */
    height: 100vh;
    /* -webkit-background-size: cover; */
    /* -moz-background-size: cover; */
    /* -o-background-size: cover; */
    background-size: cover;
}

.videoTag{
    height: 100%;
    width: 100%;
}

.Home-banner-text {
    position: relative;
    height: 80vh;
    justify-content: center;
    display: flex;
    align-items: center;
}

.Home-banner-text h1 {
    font-size: 50px;
    font-family: 'Lora', serif;
    font-weight: bold;
    color: white;
    margin: 0px;
    padding: 0px;

}

.Text {
    position: absolute;
    left: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    clip-path: polygon(0 0, 100% 100%, 0% 100%);
}

.HB-Text {
    width: 100%;
    text-align: left;
    margin-left: 20vh;
}

.HB-Text p {
    font-size: 25px;
    font-family: 'Playfair Display', serif;
    color: white;

}


/* ///////////////////////////////////////////// Section 2 ////////////////////////////////////////////////// */


.HomeSection2 {
    background-color: #f3f0ed;
}

.Section2-Details {
    padding-bottom: 10vh;
    padding-top: 15vh;
}

.Section2-Image {
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    width: 100%;
}

.TreeImage {
    height: 15vh;
    width: 15vh;
}

.TreeImage img {
    height: 100%;
    width: 100%;
}

.Section2-title {
    margin-top: 5vh;
    margin-bottom: 5vh;
}

.Section2-title h3 {
    font-family: 'Nuosu SIL', serif;
    font-size: 30px;
    font-weight: 500;
}

.Section2-title h3 strong {
    font-family: 'Dancing Script', cursive;
    font-size: 30px;
    font-weight: 500;
    letter-spacing: 2px;
    color: #ffad01;
}




.Section2-description {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.Section2-description p {
    width: 95vh;
    font-family: 'Nuosu SIL', serif;
    font-size: 16px;
    font-weight: 500;
}



/*  */

.SectionShow1 {}

.ShowImage1 {
    height: 20vh;
}

.ShowImage1 img {
    height: 100%;
    width: 100%;
}




/* ///////////////////////////////////////////// Section 3 ////////////////////////////////////////////////// */

.HomeSection3 {}

.HomeFacility {}

.HomeFacility-title {}

.HomeFacility-title h3 {}

.HomeFacility-show {
    display: flex;
    justify-content: center;
    align-items: center;
    justify-content: center;

}

.HomeFacility-show-Image {
    height: 10vh;
    width: 40vh;
}

.HomeFacility-show-Image img {
    height: 100%;
    width: 100%;
}

.HomeFacility-qoutes p {
    margin: 0px;
    font-family: 'Nuosu SIL', serif;
    font-size: 16px;
    font-weight: 500;
}

.HomeFacility-Main {
    /* border: 1px solid black; */
    margin-top: 10vh;
}

.HFacility-Con {
    /* border: 1px solid blue; */
    height: 20vh;
    margin: 10px;
}

.HFacility-icon {
    /* border: 1px solid red; */

}

.HFacility-icon svg {
    height: 5vh;
    width: 5vh;
    margin: 15px;
}

.HFacility-name {
    /* border: 1px solid green; */
}

.HFacility-name h4 {
    margin: 15px;
    text-transform: uppercase;
    font-size: 17px;
    font-weight: 400;
}

.HomeFacility-title h3 {
    text-transform: uppercase;
    font-family: 'Nuosu SIL', serif;
    font-weight: 500;
    font-size: 37px;
}





/* ///////////////////////////////////////////// Section 4 //////////////////////////////////////////////////  */


.HomeSection4 {
    /* border: 1px solid red; */
    padding-top: 20vh;
    background-color:var(---primeColor) ;
}


/* left col  */

.HS4-AboutUs-Images-con {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    position: relative;
}

.HS4-AboutUs-Images {
    margin: 20px;
    height: 70vh;
    width: 57vh;
}

.HS4-AboutUs-Images img {
    height: 100%;
    width: 100%;
}

.HS4-AboutUs-overlay-Image {
    height: 50vh;
    width: 42vh;
    border: 12px solid white;
    position: absolute;
    top: 30%;
    left: 0;
}

.HS4-AboutUs-overlay-Image img {
    height: 100%;
    width: 100%;
}

.HS4-Aboutus {
    /* border: 1px solid blue; */
}

.HS4-AboutUs-Title {
    text-align: left;
    margin: 10px;
}

.HS4-AboutUs-Title h5 {
    font-size: 15px;
    margin: 2px;
    color: var(---TheamGoldColor);
}

.HS4-AboutUs-head {
    /* border: 1px solid red; */
    text-align: left;
    margin: 10px;
}

.HS4-AboutUs-head h3 {
    text-transform: uppercase;
    font-family: 'Nuosu SIL', serif;
    font-size: 30px;
}

.HS4-AboutUs-head p {
    font-family: var(---paraFont);
    font-size: 14px;
    color: gray;
}

.HS4-fea {
    /* border: 1px solid black; */
    display: flex;
}

.HS4-AboutUs-fea-Images {
    /* border: 1px solid blue; */
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.HS4-fea-Images {
    margin: 20px;
    box-shadow: 0px 2px 10px rgb(0 0 0 / 40%);
    height: 48vh;
    width: 100%;
}

.HS4-fea-Images img {
    height: 100%;
    width: 100%;
}

.HS4-AboutUs-fea-detail {
    /* border: 1px solid blue; */
}

.HS4-pacefull-con {
    padding: 10px;
}

.HS4-pacefull-Iamge-con {
    /* border: 1px solid red; */
    height: 8vh;
    width: 8vh;
    margin: 10px;
    background-color: var(---TheamGoldColor);
    padding: 5px;
}

.HS4-pacefull-Iamge-con svg {
    height: 100%;
    width: 100%;
    color: #0000ffdb;
}

.HS4-pacefull-con h6 {
    text-align: left;
    margin: 10px;
}

.HS4-pacefull-con p {
    text-align: left;
    font-size: 11px;
    margin: 10px;
}



/* ///////////////////////////////////////////// Section 5 //////////////////////////////////////////////////  */

.HomeSection5 {
    /* border: 1px solid black; */
    padding-top: 20vh;
    background: url("../Images/HomeBackground.png");
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-position: fixed;
}


.HomeSection5-head-info {
    padding-bottom: 5vh;
}

.HS5-main-title {
    /* border: 1px solid red; */
}

.HS5-main-title h4 {
    margin: 0px;
    text-transform: uppercase;
    font-family: 'Nuosu SIL', serif;
    font-weight: 500;
    font-size: 37px;
}

.HS5-main-title-para p {
    font-family: 'Nuosu SIL', serif;
    font-size: 16px;
    font-weight: 500;
    color: var(---TheamGoldColor);
}

.HS5-left {
    /* border: 1px solid black; */

}

.HS5-left-Image {
    height: 40vh;
}

.HS5-left-Image img {
    height: 100%;
    width: 100%;
}

.HS5-with-partner {
    margin: 10px;
}

.HS5-with-partner h4 {
    font-family: 'Nuosu SIL', serif;
    text-align: left;
}

.HS5-with-partner p {
    text-align: left;
    font-size: 14px;
    color: gray;
}


.HS5-with-friends {
    margin: 10px;
}

.HS5-with-friends h4 {
    font-family: 'Nuosu SIL', serif;
    text-align: left;
}

.HS5-with-friends p {
    text-align: left;
    font-size: 14px;
    color: gray;
}

/* /////// row right///// */

.HS5-right {
    padding: 100px 0px 35px 0px;
}

.aboutImg {
    width: 100%;
    height: 100%;
    max-width: 280px;
    max-height: 250px;
}
@media (max-width: 700px){
    .HS5-left-Image {
        margin: 20px;
        box-shadow: 0 0 5px gray;
        border-radius: 10px;
    }
}

@media(max-width:700px){
    .aboutImg {
        width: 100%;
        height: 100%;
        object-fit: cover;
        max-height: 332px;
    max-width: 345px;
        /* max-width: 280px;
        max-height: 250px; */
    } 
}

.aboutImg img {
    width: 100%;
    height: 100%;
}

.firstImage {
    transform: translate(60px, -62px);
}

.lastImage {
    transform: translate(-60px, 40px);
}

.aboutText {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: end;
    flex-direction: column;
}


@media(max-width:990px) {
    .aboutSection {
        padding: 50px 0 40px 0;
    }

    .firstImage {
        transform: translate(10px, -30px);
    }

    .thirdImage {
        transform: translate(0, -10px);
    }

    .lastImage {
        transform: translate(-10px, 20px);
    }
}

@media(max-width:767px) {
    .aboutSection {
        padding: 50px 0 40px 0;
    }

    .firstImage {
        transform: translate(10px, -30px);
    }

    .thirdImage {
        transform: translate(0, -10px);
    }

    .lastImage {
        transform: translate(-10px, 20px);
    }
}


/* ///////////////////////////////////////////// Section 6 //////////////////////////////////////////////////  */

.HomeSection6 {
    background-color: var(---primeColor);
    padding-top: 20vh;
}

.HS6-main-title h4 {
    margin: 0px;
    text-transform: uppercase;
    font-family: 'Nuosu SIL', serif;
    font-weight: 500;
    font-size: 37px;
}

.HomeSection6-head-info {
    /* background-color: var(---primeColor); */
    padding-bottom: 15vh;
}

/* swiper  */

.HS6-RoomDetail {
    /* border: 1px solid red; */
}

.HS6-RoomDetail-description {
    /* border: 1px solid blue; */
}

.HS6-RoomDetail-description h3 {
    text-transform: uppercase;
    text-align: left;
    font-family: 'Libre Baskerville', serif;
    font-weight: 600;
    margin: 10px;
}

.HS6-RoomDetail-description h6 {
    text-transform: uppercase;
    text-align: left;
    font-size: 12px;
    margin: 10px;
}

.HS6-Facility-logo-con {
    /* border: 1px solid blue; */
    display: flex;
}


.HS6-Icons {
    /* border: 1px solid bisque; */
    height: 7vh;
    width: 7vh;
    margin: 15px;
    padding: 5px;
}

.HS6-Icons svg {
    height: 100%;
    width: 100%;
}

.HS6-RoomDetail-para {}

.HS6-RoomDetail-para p {
    color: gray;
    margin: 10px;
    text-align: left;

}

.HS6-roomImage {
    height: 75%;
    margin: 10px;
}

.HS6-roomImage img {
    height: 100%;
    width: 100%;
    max-width: 500px;
    max-height: 500px;
    object-fit: cover;
}

.swiper-button-prev {
    display: block;
}

.swiper-button-next {
    display: block;
}



/* ///////////////////////////////////////////// Section 7 //////////////////////////////////////////////////  */


.HomeSection7 {
    /* border: 1px solid red; */
    padding-top: 20vh;
}

.HS7-main-title h4 {
    margin: 0px;
    text-transform: uppercase;
    font-family: 'Nuosu SIL', serif;
    font-weight: 500;
    font-size: 37px;
}

.HomeSection7-head-info {
    padding-bottom: 10vh;
}


.HS7-Activity-fist {
    margin: 20px 0px;

}

.HS7-Activity-fist:hover {
    box-shadow: 0px 2px 10px rgb(0 0 0 / 80%);
    border-radius: 5px;
}



.HS7-activity-Image {
    /* border: 1px solid black; */
    height: 40vh;
}

.HS7-activity-Image img {
    height: 100%;
    width: 100%;
}

.HS7-activity-Name {
    padding: 10px;
}

.HS7-activity-Name h5 {
    font-family: 'Libre Baskerville', serif;
}

.HS7firstImage {
    transform: translate(0px, 85px);
}


/* ///////////////////////////////////////////// Section 8 //////////////////////////////////////////////////  */
.cutomerReview {
    background-color: var(---primeColor);
    padding-top: 20vh;
    padding-bottom: 10vh;
}


/* ///////////////////// */

.swiper-button-prev {
    display: block;
}


.swiper-button-prev,
.swiper-rtl .swiper-button-next {
    display: block;
}



/* ///////////////////////////////////////////// resturant section //////////////////////////////////////////////////  */


.ResturantSection2 {
    padding-top: 20vh;
    padding-bottom: 10vh;
}

.Resturant-Details {
    /* border: 1px solid blue; */
}

.Resturant-Title {
    position: relative;
    margin: 20px;
}

.Resturant-Title h3{
    font-size: 50px;
    font-family: 'Playfair Display SC', serif;
    font-weight: bold;
}

.Resturant-Title h2 {
    position: absolute;
    top: -75px;
    left: 0;
    right: 0;
    font-size: 170px;
    font-family: 'Playfair Display SC', serif;
    z-index: -1;
    color: #f2f2f2;
}

.Resturant-Title p {
    font-weight: 300;
    font-size: 13px;
    text-transform: uppercase;
    letter-spacing: 3px;
    margin-bottom: 3px;
}

.Showcon {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.Show-Image {
    height: 8vh;
    width: 30vh;
}

.Show-Image img {
    height: 100%;
    width: 100%;
}

.swiper-pagination {
    display: none !important;
}


.ResturantInfo {
    padding-top: 20px;
}

.ResturantInfo h4 {
    font-family: 'Playfair Display SC', serif;
    font-weight: 700;
    line-height: 1.1;
    text-transform: uppercase;
    margin-top: 27px;
    margin-bottom: 13.5px;
}


.ResturantInfopara {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.ResturantInfopara p {
    text-align: center;
    width: 90vh;
    font-size: 16px;
    font-weight: 200;
    line-height: 1.5;
}

.HomeResImage {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}


@media(max-width:2560px) {
    .HS4-AboutUs-fea-detail {
        margin: 8vh 1vh 1vh 17vh;
    }
}

@media(max-width:1770px) {
    .HS4-AboutUs-fea-detail {
        margin: 6vh 1vh 1vh 12vh;
    }
}

@media(max-width:1440px) {
    .HS4-AboutUs-fea-detail {
        margin: 1vh 1vh 1vh 1vh;
    }
}

@media(max-width:1200px) {
    .firstImage {
        transform: translate(10px, -64px);
    }

    .lastImage {
        transform: translate(0px, 40px);
    }

    .Resturant-Title h2 {
        top: -44px;
        font-size: 125px;
    }
}

@media(max-width:995px) {
    .HS4-AboutUs-fea-detail {
        margin: 0vh 1vh 1vh 8vh;
    }
}

@media(max-width:770px) {
    .Resturant-Title h2 {
        top: -30px;
        font-size: 93px;
    }

    .Events_details-Con {
        margin-top: 20px;
    }

    .HS7firstImage {
        transform: translate(0px, 0px);
        margin: 20px;
    }

    .HS7-activity-Image {
        height: 55vh;
    }

    .HS4-fea {
        display: grid;
        justify-content: center;
        align-items: center;
    }
}

@media(max-width:570px) {

    .Resturant-Title h2 {
        display: none;
    }
}

@media(max-width:430px) {
    .Section2-title {
        margin-top: 0px;
        margin-bottom: 3px;
    }
    .HS4-AboutUs-head p {
        width: auto;
    }

    .HB-Text {
        margin: 30px;
    }

    .Home-banner {
        background: linear-gradient(to right, rgb(0, 0, 0, .4), rgb(0, 0, 0, .5)), url("../4BHvilla-webp/4bh\(1\).webp");
        /* border: 1px solid red; */
        height: 100vh;
        /* -webkit-background-size: cover; */
        /* -moz-background-size: cover; */
        /* -o-background-size: cover; */
        background-size: cover;
    }

    .HB-Text p {
        font-size: 15px;
        font-family: 'Courgette', cursive;
        color: white;
    }

    .Home-banner-text h1 {
        font-size: 30px;
        color: white;
    }

    .HS4-AboutUs-fea-detail {
        margin: 0px;
    }

    .HS4-fea-Images {
        border-radius: 5px;
        height: auto;
        width: auto;
        border-radius: 5px;
    }

    .HS4-fea-Images img {
        border-radius: 5px;
    }

    .HS4-pacefull-con p {
        width: 100%;
    }

    .HomeSection4 {
        padding-top: 10vh;
    }

    .HomeSection5 {
        padding-top: 10vh;
    }

    .HS5-main-title h4 {
        font-size: 25px;
    }

    .HS5-left-Image {
        margin: 20px;
        box-shadow: 0 0 5px gray;
        border-radius: 10px;
    }

    .HS5-left-Image img {
        border-radius: 10px;
    }

    .HS5-with-partner h4 {
        text-align: center;
    }

    .HS5-with-partner p {
        text-align: center;
    }

    .HS5-with-friends h4 {
        text-align: center;
    }

    .HS5-with-friends p {
        text-align: center;
    }

    .HomeSection6 {
        padding-top: 10vh;
    }

    .HomeSection6-head-info {
        padding-bottom: 5vh;
    }

    .ResturantSection2 {
        padding-top: 0px;
        padding-bottom: 2vh;
    }

    .Resturant-Title h1 {
        font-size: 27px;
    }

    .Resturant-Title p {
        letter-spacing: 0px;
        font-size: 10px;
    }

    .EventImages {
        /* border: 1px solid red; */
        height: 45vh !important;
        border-radius: 10px;
    }

    .EventImages img {
        border-radius: 10px;
    }

    .Events-Info {
        padding: 15px !important;
    }

    .Events-text h1 {
        font-size: 20px !important;
    }

    .Events-text p {
        font-size: 13px;
    }

    .HomeSection7 {
        padding-top: 0vh;
    }

    .HS7-main-title h4 {
        font-size: 25px;
    }

    .HomeSection7-head-info {
        padding: 0px;
    }

    .HS7-activity-Image {
        height: 45vh;
    }

    .cutomerReview {
        padding-top: 10vh;
    }

    .Rtitle h1 {
        font-size: 30px !important;
    }

    .Home-banner {
        height: 60vh;
    }

    .Home-banner-text {
        height: 45vh;
    }

    .HFacility-name h4 {
        margin: 5px;
        font-size: 15px;
    }

    .HS4-AboutUs-head h3 {
        font-size: 27px;
    }

    .Accomo-rooms-Image1 {
        height: 60vh;
    }
}
@media(max-width:700px){
    .Resturant-Title h3{
        font-size: 35px;
    }
    
}