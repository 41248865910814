.navbar {
    background-color: transparent;
    position: relative;
}

.mid-logo {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 0px 20px 0px 0px;
}

.navbar-nav {
    width: 100%;
    justify-content: center;
}

.navbar-nav a {
    color: white;
}


.LogoImage {
    height: auto;
    width: 134px;
    /* height: 13vh;
    width: 13vh; */
}

.LogoImage img {
    height: 100%;
    width: 100%;
}


.navbar-nav a {
    color: #000;
    font-size: 14px;
    text-transform: uppercase;
    text-decoration: none;
    font-weight: 500;
    line-height: 1em;
    padding: 12px 0;
    margin: 0 15px;
    letter-spacing: 2px;
    text-align: left;
}
@media (max-width:700px){
    
.navbar-nav a {
    color: #fff;
    font-size: 14px;
    text-transform: uppercase;
    text-decoration: none;
    font-weight: 500;
    line-height: 1em;
    padding: 12px 0;
    margin: 0 15px;
    letter-spacing: 2px;
    text-align: left;
}
}

.SmallSLogo{
    position: absolute;
    right: 0;
    top: 0;
}
/* /////  PopUp Form ///  */


.btn:focus {
    box-shadow: none;
}

.Check-In {
    margin-top: 12px;
}

.Check-In h6 {
    font-size: 15px;
    font-weight: initial;
}

.Check-Out {
    margin-top: 12px;
}

.Check-Out h6 {
    font-size: 15px;
    font-weight: initial;
}

.form-label {
    margin: 5px 2px 2px 2px;
}

@media(max-width:991px){
    .navbar-brand{
        display: none;
    }
    .navbar-collapse {
        background: linear-gradient(90deg,rgba(25,25,25,.8),rgba(25,25,25,.6));
        border: 2px solid rgb(186, 186, 186);
        margin-top: 8px;
        border-radius: 5px;
        position: absolute;
        top: 47px;
        z-index: 3;
        width: 80%;
        backdrop-filter: blur(8px);
    }
}

@media(min-width:991px){
    .SmallSLogo{
        display: none;
    }
   
}
.navbar-toggler{
    background-color: #000;
}

/* @media (min-width:1024px ) and (max-width:1820px) {
    .navbar-nav{
        display: flex;
        justify-content: center;
        align-items: center;
    }
} */
@media (max-width:991px) {
    /* @media (max-width: 700px) */
.navbar-nav a {
    color: #fff;
    font-size: 14px;
    text-transform: uppercase;
    text-decoration: none;
    font-weight: 500;
    line-height: 1em;
    padding: 12px 0;
    margin: 0 15px;
    letter-spacing: 2px;

}
}
@media (max-width:700px){
    .navbar{
        height: 10vh;
    }
}