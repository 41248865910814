/* ///////////////////////////////////////////// Section 1 ////////////////////////////////////////////////// */

.ContactUs-banner {
    background: linear-gradient(to right, rgb(0, 0, 0, .5), rgb(0, 0, 0, .6)), url("../greenHillVillas-webp/banner2.webp");
    height: var(---bannerHeight);
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

.ContactUs-banner-text {
    position: relative;
    height: 80vh;
    justify-content: center;
    display: flex;
    align-items: center;
}

.ContactUs-banner-text h1 {
    font-size: 50px;
    font-family: 'Lora', serif;
    font-weight: bold;
    color: white;
    margin: 0px;
    padding: 0px;

}

.Text {
    position: absolute;
    left: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    clip-path: polygon(0 0, 100% 100%, 0% 100%);
}

.CUS1-Text {
    width: 100%;
    text-align: left;
    margin-left: 20vh;
    margin-right: 20px;
}

.CUS1-Text p {
    font-size: 25px;
    font-family: 'Playfair Display', serif;
    color: white;
}

/* ///////////////////////////////////////////// Section 2 ////////////////////////////////////////////////// */

.Contact-Us-2 {
    /* border: 1px solid red; */
    margin-top: 100px;
}


.Office-Details {
    margin: 20px;

    font-family: 'Playfair Display', serif;
    color: rgb(200, 200, 200);
}

.Office-Details h3 {
    font-size: 40px;
}

.Contact-Us-2 {
    /* border: 1px solid red; */
    margin-top: 100px;
}


@media(max-width:1000px) {
    .Contact-info {
        margin: 20px;
    }
}

.Contact-info-icon svg {
    font-size: 48px;
    color: black;
}

.Contact-info-icon:hover svg {
    color: black;

}

.Contact-info-item {
    /* border: 1px solid blue; */
}

.Contact-info-icon {
    /* border: 1px solid purple; */
}


.Contact-info-text {
    margin-top: 12px;
    font-family: 'Open Sans', sans-serif;
    color: black;
}

.Contact-info-text {
    font-family: 'Cormorant SC', serif;
}

.Contact-info-text button {
    background-color: transparent;
    border: none;
    font-size: 25px;
}

.Contact-info-text button a{
    color: black;
}


/* ///////////////////////////////////////////////////////////////////////////////////////////// */


/* //////////////////////////////////////////// section 4 /////////////////////////////////////////// */

.ContactUs-form-Section-4 {
    margin-top: 10vh;
    margin-bottom: 10vh;
}

.contact-form-image {
    height: 60vh;
    box-shadow: 0 0 5px #808080;
    border-radius: 5px;
}

.form-control {
    background-color: rgb(228, 228, 228);
    height: 50px;
}

.Email {
    margin-top: 20px;
    margin-bottom: 20px;
}

.Phone-no {
    margin-top: 13px;
    margin-bottom: 20px;
}

.message textarea {
    height: 100px;
}

.form-submit-button {
    border: none;
    margin-top: 20px;
    width: 200px;
    height: 35px;
    border-radius: 5px;
}

@media(max-width:767px) {
    .Name-input {
        margin-top: 20px;
    }
    .CUS1-Text {
        margin-left: 50px;
    }
}

@media(max-width:430px) {
    .ContactUs-banner {
        background: linear-gradient(to right, rgb(0, 0, 0, .5), rgb(0, 0, 0, .6)), url("../greenHillVillas-webp/banner2.webp");
        height: 100vh;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
    }

    .CUS1-Text {
        margin-left: 50px;
    }

    .ContactUs-banner-text h1 {
        font-size: 30px;
        color: white;
    }

    .CUS1-Text p {
        font-size: 15px;
        font-family: 'Courgette', cursive;
        color: white;
    }

    .ContactUs-banner {
        height: 60vh;
    }

    .ContactUs-banner-text {
        height: 45vh;
    }

    .Section2-Details {
        padding-top: 5vh;
    }
}
@media (max-width:700px){
    .CUS1-Text p{
        font-size: 20px;
    }
    .ContactUs-banner-text h1 {
        font-size: 30px;
    }
}