/* ///////////////////////////////////////////// Section 1 ////////////////////////////////////////////////// */

.Restaurant-banner {
    background: linear-gradient(to right, rgb(0, 0, 0, .5), rgb(0, 0, 0, .6)), url("../greenHillVillas-webp/banner2.webp");
    height: var(---bannerHeight);
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

.Restaurant-banner-text {
    position: relative;
    height: 80vh;
    justify-content: center;
    display: flex;
    align-items: center;
}

.Restaurant-banner-text h2 {
    font-size: 50px;
    font-family: 'Lora', serif;
    font-weight: bold;
    color: white;
    margin: 0px;
    padding: 0px;
}
.Restaurant-banner-text h1 {
    font-size: 50px;
    font-family: 'Lora', serif;
    font-weight: bold;
    color: white;
    margin: 0px;
    padding: 0px;
}

.Text {
    position: absolute;
    left: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    clip-path: polygon(0 0, 100% 100%, 0% 100%);
}

.RS1-Text {
    width: 100%;
    text-align: left;
    margin-left: 20vh;
}

.RS1-Text p {
    /* color: white; */
    font-size: 25px;
    font-family: 'Playfair Display', serif;
    color: white;
}


/* \\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\ */

.RestaurantsSection2 {
    /* border: 1px solid red; */
    padding-bottom: 15vh;
    padding-top: 15vh;
    background-color: var(---primeColor);
    /* background-color: rgb(23, 24, 25); */
    position: relative;
    /* position: relative;
    animation-name: example;
    animation-duration: 4s;
    animation-iteration-count: infinite; */
}

.RestaurantsSection2-con {
    /* border: 1px solid blue; */
    z-index: 1000;
}

.Breakfast-con {
    /* border: 1px solid green; */
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    z-index: 100;
}

.Breakfast-Name {
    /* border: 1px solid saddlebrown; */
    /* margin: 10px; */
}

.Breakfast {
    /* border: 1px solid aqua; */
    height: 50vh;
    width: 45vh;
    margin: 30px 0px 20px 0px;
}

.Breakfast-Name h5 {
    font-family: Nuosu SIL, serif;
    font-size: 25px;
    color: whitesmoke;
}


.Breakfast img {
    height: 100%;
    width: 100%;
}

.Breakfast-mid {
    /* border: 1px solid darkblue; */
    z-index: 200;

}

.Breakfast-Image {
    display: flex;
    justify-content: center;
    align-items: center;
}

.Breakfast-Details-con {
    /* border: 1px solid slateblue; */
}

.Breakfast-Details-con h5 {
    text-transform: uppercase;
    font-weight: 700;
    color: var(---TheamGoldColor);
    letter-spacing: 0.40em;
    font-size: 12px;
    margin: 20px 0px 10px 0px;
}

.Breakfast-Details-con h2 {
    background: none;
    line-height: 1.20em;
    color: var(--heading-color);
    font-family: 'Nuosu SIL', serif;
    font-size: 35px;
    color: #000;
}

.Breakfast-Details-con p {
    font-size: 15px;
    margin: 15px 10px;
    color: #000;
}

.Animated-Image-con1 {
    /* border: 1px solid blue;   */
    display: inline-block;
    position: absolute;
    top: 0;
    right: 0;
    /* z-index: -1; */
}

.Animated-Image1 {
    /* border: 1px solid green; */
    height: 40vh;
    width: 40vh;
    /* position: absolute;
     top: 0;
     right: 0; */
    position: relative;
    animation-name: example;
    animation-duration: 4s;
    animation-delay: -1s;
    animation-iteration-count: infinite;
    /* -webkit-animation-name: float_up_down;
    -webkit-animation-duration: 5s;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear; */
}

.Animated-Image1 img {
    height: 100%;
    width: 100%;
}


@keyframes example {
    0% {
        /* background-color: red; */
        left: 0px;
        top: 0px;
    }

    25% {
        /* background-color: yellow; */
        /* left: 200px; */
        top: 0px;
    }

    50% {
        /* background-color: blue; */
        /* left: 200px; */
        top: 100px;
    }

    75% {
        /* background-color: green; */
        left: 0px;
        top: 100px;
    }

    100% {
        /* background-color: red; */
        left: 0px;
        top: 0px;
    }
}

.Animated-Image-con2 {
    /* border: 1px solid blue; */
    display: inline-block;
    position: absolute;
    bottom: 0;
    left: 0;
    /* z-index: -1; */
}

.Animated-Image2 {
    /* border: 1px solid green; */
    height: 40vh;
    width: 40vh;
    /* position: absolute;
     top: 0;
     right: 0; */
    position: relative;
    animation-name: image2;
    animation-duration: 4s;
    animation-delay: -1s;
    animation-iteration-count: infinite;
    /* -webkit-animation-name: float_up_down;
    -webkit-animation-duration: 5s;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear; */
}

.Animated-Image2 img {
    height: 100%;
    width: 100%;
}


@keyframes image2 {
    0% {
        /* background-color: red; */
        left: 0px;
        top: 0px;
    }

    25% {
        /* background-color: yellow; */
        /* left: 200px; */
        top: 0px;
    }

    50% {
        /* background-color: blue; */
        /* left: 200px; */
        top: -100px;
    }

    75% {
        /* background-color: green; */
        left: 0px;
        top: -100px;
    }

    100% {
        /* background-color: red; */
        left: 0px;
        top: 0px;
    }
}


/* ///////////////////////////////////////////// Section 3 ////////////////////////////////////////////////// */

.RestaurantsSection3 {
    /* border: 1px solid red; */
    background-color: var(---primeColor);
    /* background-color: rgb(14, 13, 12); */
    padding-bottom: 10vh;
}

.RS3-Details {
    /* border: 1px solid blue; */
    padding-top: 10vh;
}

.RS3-Details h6 {
    text-transform: uppercase;
    font-weight: 700;
    color: var(---TheamGoldColor);
    letter-spacing: 0.40em;
    font-size: 12px;
    margin: 20px 0px 10px 0px;
}

.RS3-Details h2 {
    background: none;
    line-height: 1.20em;
    font-family: 'Nuosu SIL', serif;
    font-size: 35px;
    color: #000;
}

.RS3-Swaiper {
    /* border: 1px solid green; */
}

.Swiper-con {
    /* border: 1px solid red; */
}

.Swaiper-Image {
    overflow: hidden;
    height: 50vh;
    /* border: 1px solid rebeccapurple; */
    box-shadow: 0 0 10px var(---TheamGoldColor);
    margin-top: 10px;
}

.Swaiper-Image img {
    height: 100%;
    width: 100%;
    -webkit-transition: .8s ease-in-out;
    transition: .8s ease-in-out;
}

.Swaiper-text {
    /* border: 1px solid red; */
    text-align: center;
    margin: 25px 0px;
}

.Swaiper-text h4 {
    font-family: Nuosu SIL, serif;
    font-size: 25px;
    color: var(---TheamGoldColor);
    text-transform: capitalize;
}

.RS3-Swaiper {
    margin: 70px 0px 30px 0px;
}

.topI {
    margin-top: 60px;
}

.Swiper-con:hover .Swaiper-Image img {
    -webkit-transform: rotate(5deg) scale(1.2);
    -webkit-transition: .8s ease-in-out;
    transition: .8s ease-in-out;
}

/* /////////////////////////////// section 4 ///////////////////////////////////  */

.RestaurantsSection4 {
    /* border: 1px solid red; */
    padding-top: 10vh;
    padding-bottom: 10vh;
}

.RS-4-deatils {
    /* border: 1px solid blue; */
}

.RS-4-Image-con {
    /* border: 1px solid green; */
    display: flex;
    justify-content: center;
    align-items: center;
}

.RS-4-Image {
    /* border: 1px solid greenyellow; */
    height: 65vh;
    width: 70vh;
}


.RS-4-Image img {
    height: 100%;
    width: 100%;
}

.RS-4-Details {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.RS-Info {
    text-align: left;

}

.RS-Info h5 {
    text-transform: uppercase;
    font-weight: 700;
    color: var(---TheamGoldColor);
    letter-spacing: 0.40em;
    font-size: 12px;
    margin: 20px 0px 10px 0px;
}

.RS-Info h2 {
    background: none;
    line-height: 1.20em;
    font-family: 'Nuosu SIL', serif;
    font-size: 35px;
    color: black;
}

.RS-Info p {
    font-family: 'Nuosu SIL', serif;
    font-size: 16px;
    font-weight: 500;
}

/* ///////////////////// Section 5 /////////////////////// */

.RestaurantsSection5 {
    /* border: 1px solid red; */
    padding-top: 10vh;
    padding-bottom: 10vh;
}

.Menu-Details {
    /* border: 1px solid red; */
    margin: 20px 0px 40px 0px;
}

.Menu-Details h5 {
    text-transform: uppercase;
    font-weight: 700;
    color: var(---TheamGoldColor);
    letter-spacing: 0.40em;
    font-size: 12px;
    margin: 5px 0px;
}


.Menu-Details h2 {
    background: none;
    line-height: 1.20em;
    font-family: 'Nuosu SIL', serif;
    font-size: 35px;
    color: black;
}

.RS5-menu {
    /* border: 1px solid blue; */
}

.Swiper-Menu {
    /* border: 1px solid green; */
    display: flex;
    justify-content: center;
    align-items: center;
}

.Swiper-Menu-Image {
    /* border: 1px solid saddlebrown; */
    height: 90vh;
    width: 70vh;
    box-shadow: 0 0 10px var(---TheamGoldColor);
}

.Swiper-Menu-Image img {
    height: 100%;
    width: 100%
}


@media(max-width:500px) {
    .HB-Text {
        margin: 30px;
    }

    .Restaurant-banner {
        /* background: linear-gradient(to right, rgb(0, 0, 0, .5), rgb(0, 0, 0, .6)), url("../Images/SortImages/Untitled-5.jpg"); */
        height: 60vh;
        -webkit-background-size: cover;
    }


    .Restaurant-banner-text {
        position: relative;
        height: 40vh;
        justify-content: center;
        display: flex;
        align-items: center;
    }

    .Restaurant-banner-text h2 {
        font-size: 30px;
    }

    .RS1-Text p {
        font-size: 15px;
    }

    .RS-4-Image {
        height: 50vh;
        width: 60vh;
    }
    .topI {
        margin-top: 0px;
    }

    .Swaiper-Image {
        margin: 45px;
    }

    .Swiper-Menu-Image {
        height: 70vh;
        width: 65vh;
    }
}

@media(max-width:375px){
    .Breakfast {
        height: 40vh;
        width: 35vh;
    }
}
@media(max-width:700px){
    .Restaurant-banner-text h1{
        font-size: 30px;
    }
}