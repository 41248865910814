/* ///////////////////////////////////////////// Section 1 ////////////////////////////////////////////////// */

.Event-banner {
    background: linear-gradient(to right, rgb(0, 0, 0, .5), rgb(0, 0, 0, .6)), url("../greenHillVillas-webp/banner2.webp");
    height: var(---bannerHeight);
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

.Event-banner-text {
    position: relative;
    height: 80vh;
    justify-content: center;
    display: flex;
    align-items: center;
}

.Event-banner-text h2 {
    font-size: 50px;
    font-family: 'Lora', serif;
    font-weight: bold;
    color: white;
    margin: 0px;
    padding: 0px;
}
.Event-banner-text h1 {
    font-size: 50px;
    font-family: 'Lora', serif;
    font-weight: bold;
    color: white;
    margin: 0px;
    padding: 0px;
}

.Text {
    position: absolute;
    left: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    clip-path: polygon(0 0, 100% 100%, 0% 100%);
}

.ES1-Text {
    width: 100%;
    text-align: left;
    margin-left: 20vh;
}

.ES1-Text p {
    /* color: white; */
    font-size: 25px;
    font-family: 'Playfair Display', serif;
    color: white;
}


/* \\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\ */

.Room-titile {
    margin: 50px;
    position: relative;
}

.Room-titile h1 {
    text-transform: uppercase;
    font-family: 'Nuosu SIL', serif;
    font-weight: 500;
    font-size: 37px;
}

.Room-titile h2 {
    position: absolute;
    top: -50px;
    left: 0;
    right: -15px;
    font-family: 'Qwitcher Grypen', cursive;
    font-size: 85px;
    color: lightgrey;
    z-index: -1;

}

.EventPage-title {
    padding-top: 10vh;
    background-color: #f3f0ed;
}

.EventPage-title h1 {
    text-transform: uppercase;
    font-family: 'Nuosu SIL', serif;
    font-weight: 500;
    font-size: 37px;
    padding: 30px;
    margin: 0px;
}


/* ///////////////////////////////////////////// Section 2 ////////////////////////////////////////////////// */



.EventSection2 hr {
    margin: 5vh;
}

.EventSection3 hr {
    margin: 5vh;
}

.EventSection4 hr {
    margin: 5vh;
}

.EventImageCon {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.EventImages {
    /* border: 1px solid red; */
    height: 65vh;
}

.EventImages img {
    height: 100%;
    width: 100%;
}


.Events_details-Con {
    /* border: 1px solid blue; */
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.Events-Info {
    /* border: 1px solid black; */
    margin: 20px;
    width: 70vh;
    padding: 30px;
    box-shadow: 0 0 30px rgb(32 32 32 / 15%);
    position: relative;
}

.Events-Info::before {
    position: absolute;
    content: "";
    height: 10px;
    width: 38px;
    background-color: #c3a165;
    top: -20px;
    left: -20px;
}

.Events-text {}

.Events-text::after {
    position: absolute;
    content: "";
    height: 10px;
    width: 38px;
    background-color: #c3a165;
    top: -5px;
    left: -34px;
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
}

.Events-text h3 {
    font-size: 14px;
    color: #c3a165;
    text-transform: uppercase;
    font-weight: 600;
    text-align: left;
}

.Events-text h2 {
    font-size: 33px;
    color: #000;
    position: relative;
    padding-bottom: 25px;
    text-align: left;
    font-family: 'Lora', serif;
    font-weight: 600;
}


.Events-text h2::before {
    position: absolute;
    content: "";
    height: 3px;
    width: 75px;
    background-color: #c3a165;
    bottom: 0;
}

.Events-text p {
    font-size: 16px;
    text-align: left;
    font-family: var(---paraFont);
}

hr {}

@media(max-width:430px) {
    .Event-banner {
        background: linear-gradient(to right, rgb(0, 0, 0, .5), rgb(0, 0, 0, .6)), url("../greenHillVillas-webp/banner2.webp");
        height: 100vh;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
    }

    .ES1-Text {
        margin: 30px;
    }
    .Event-banner-text h2 {
        font-size: 30px;
        color: white;
    }

    .ES1-Text p {
        font-size: 15px;
        font-family: 'Courgette', cursive;
        color: white;
    }

    .ES1-Text {
        width: 100%;
        text-align: left;
        margin-left: 7vh;
    }

    .Event-banner {
        height: 60vh;
    }


    .Event-banner-text {
        height: 45vh;
    }

    .EventPage-title h1 {
        font-size: 26px;
    }
}
.EventSection3{
    background-color: var(---primeColor);
}
/* .EventSection2{
    background-color: var(---primeColor);
} */
.EventSection5{
    background-color: var(---primeColor);
}
.below-banner-title{
    font-weight: 300;
    font-size: 13px;
    text-transform: uppercase;
    letter-spacing: 3px;
    margin-bottom: 3px;
}
@media(max-width:700px){
    .Event-banner-text h1{
        font-size: 30px;
    }
}