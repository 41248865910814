@import url('https://fonts.googleapis.com/css2?family=Playfair+Display+SC&display=swap');


/* ///////////////////////////////////////////// Section 1 ////////////////////////////////////////////////// */

.Gallery-banner {
  background: linear-gradient(to right, rgb(0, 0, 0, .5), rgb(0, 0, 0, .6)), url("../greenHillVillas-webp/banner2.webp");
  height: var(---bannerHeight);
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.Gallery-banner-text {
  position: relative;
  height: 80vh;
  justify-content: center;
  display: flex;
  align-items: center;
}

.Gallery-banner-text h1 {
  font-size: 50px;
  font-family: 'Lora', serif;
  font-weight: bold;
  color: white;
  margin: 0px;
  padding: 0px;

}

.Text {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 100%;

  width: 100%;
  clip-path: polygon(0 0, 100% 100%, 0% 100%);
}

.GS1-Text {
  width: 100%;
  text-align: left;
  margin-left: 20vh;
  margin-right: 20px;
}

.GS1-Text p {
  /* color: white; */
  font-size: 25px;
    font-family: 'Playfair Display', serif;
    color: white;
}


\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\ .Room-titile {
  margin: 50px;
  position: relative;
}

.Room-titile h1 {
  text-transform: uppercase;
  font-family: 'Nuosu SIL', serif;
  font-weight: 500;
  font-size: 37px;
}

.Room-titile h2 {
  position: absolute;
  top: -50px;
  left: 0;
  right: -15px;
  font-family: 'Qwitcher Grypen', cursive;
  font-size: 85px;
  color: lightgrey;
  z-index: -1;

}

.GalleryPage-title {
  padding-top: 10vh;
  background-color: #f3f0ed;
}

.GalleryPage-title h1 {
  text-transform: uppercase;
  font-family: 'Nuosu SIL', serif;
  font-weight: 500;
  font-size: 37px;
  margin: 0px;
}


/* ///////////////////////////////////////////// Section 2 ////////////////////////////////////////////////// */

/* /////////////////////////// Section 2 /////////////////////////////////////////// */

.Image-Gallery {
  margin-top: 5vh;
}

.Image-Gallery-title {
  position: relative;
  margin-top: 10vh;
  margin-bottom: 10vh;

}

.Image-Gallery-title h4 {
  font-family: 'PT Serif', serif;
  font-size: 35px;
  font-weight: bold;
}

.Image-Gallery-title h3 {
  position: absolute;
  font-weight: bold;
  top: -51px;
  left: 81px;
  right: 0;
  font-family: 'Qwitcher Grypen', cursive;
  font-size: 85px;
  color: lightgrey;
  z-index: -1;
}

.ImageG-title {
  display: inline-block;
  background: #dee2e6;
  border-radius: 35px;
  margin-bottom: 15px;
}

.ImageG-title:hover {
  cursor: pointer;
}

.ImageG-title h4 {
  padding: 5px 15px 0px 15px;
}

.G-Images {
  margin: 10px;
  height: 40vh;
  box-shadow: 0 0 5px #808080;
  border-radius: 5px;
}

.G-Images img {
  height: 100%;
  width: 100%;
  border-radius: 5px;
}


@media(max-width:425px) {
  .Image-Gallery-title h3 {
    font-size: 55px;
    top: -27px;
    left: 0px;
  }
}



/* //////////////////////////////////////////////////////////////////////////////////////////////////////////////// */

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.wrapper {
  max-width: 1350px;
  padding: 0px 60px;
  margin: 50px auto;
  display: flex;
  flex-wrap: wrap;
  gap: 2em;
}

.wrapper img {
  cursor: pointer;
}

/* modal */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, rgba(0, 0, 0, .5), rgba(0, 0, 0, .6));
  display: flex;
  align-items: center;
}

.overlay img {
  display: block;
  max-width: 60%;
  max-height: 80%;
  margin: 60px auto;
  box-shadow: 3px 5px 7px rgba(0, 0, 0, 0.5);
}

.overlay>span {
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 30px;
  color: #ffffff;
  z-index: 999;
  cursor: pointer;
}

.overlay-arrows_left {
  display: flex;
  justify-content: space-between;
  position: absolute;
  /* top: 50%; */
  width: 50px;
  height: 50px;
  /* left: 190px; */
  z-index: 999;
}

.overlay-arrows_left svg {
  width: 50px;
  height: 50px;
}

.overlay-arrows_right svg {
  width: 50px;
  height: 50px;
}

.overlay-arrows_right {
  display: flex;
  justify-content: space-between;
  position: absolute;
  right: 0;
  width: 50px;
  height: 50px;
  /* top: 50%; */
  z-index: 999;
}
@media (max-width:700px){
  .GS1-Text {
    margin-left: 50px;
  }
}

@media(max-width:430px) {

  .Gallery-banner-text h1 {
    font-size: 30px;
    color: white;
  }

  .GS1-Text p {
    font-size: 15px;
    font-family: 'Courgette', cursive;
    color: white;
  }

  .GS1-Text {
    margin-left: 50px;
  }

  .Gallery-banner {
    height: 60vh;
  }

  .Gallery-banner {
    /* background: linear-gradient(to right, rgb(0, 0, 0, .5), rgb(0, 0, 0, .6)), url("../Images/Gallery\ B\ Image.jpg"); */
    height: 60vh;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .Gallery-banner-text {
    height: 45vh;
  }

}
@media(max-width:700px){
  .Gallery-banner-text h1{
    font-size: 30px;
  }
  .GS1-Text p {
    font-size: 20px;
  }
}