/* ///////////////////////////////////////////// Section 1 ////////////////////////////////////////////////// */

.AboutUs-banner {
    background: linear-gradient(to right, rgb(0, 0, 0, .5), rgb(0, 0, 0, .6)), url("../greenHillVillas-webp/banner2.webp");
    height: var(---bannerHeight);
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

.AboutUs-banner-text {
    position: relative;
    height: 80vh;
    justify-content: center;
    display: flex;
    align-items: center;
}

.AboutUs-banner-text h2 {
    font-size: 50px;
    font-family: 'Lora', serif;
    font-weight: bold;
    color: white;
    margin: 0px;
    padding: 0px;

}

.Text {
    position: absolute;
    left: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    clip-path: polygon(0 0, 100% 100%, 0% 100%);
}

.ABS1-Text {
    width: 100%;
    text-align: left;
    margin-left: 20vh;
    margin-right: 20px;
}

.ABS1-Text p {
    /* color: white; */
    font-size: 25px;
    font-family: 'Playfair Display', serif;
    color: white;
}


/* \\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\ */

.Room-titile {
    margin: 50px;
    position: relative;
}

.Room-titile h1 {
    text-transform: uppercase;
    font-family: 'Nuosu SIL', serif;
    font-weight: 500;
    font-size: 37px;
}

.Room-titile h2 {
    position: absolute;
    top: -50px;
    left: 0;
    right: -15px;
    font-family: 'Qwitcher Grypen', cursive;
    font-size: 85px;
    color: lightgrey;
    z-index: -1;
}

.AB-title {
    position: relative;
    padding: 15px;
    margin: 40px;
}


.AB-title h1 {
    font-size: 50px;
    font-family: 'Playfair Display SC', serif;
    font-weight: bold;
}

.AB-title h2 {
    position: absolute;
    top: -15px;
    left: 0;
    right: 0;
    font-size: 90px;
    font-family: 'Playfair Display SC', serif;
    z-index: -1;
    color: #f2f2f2;
}


.villainfo {
    margin: 30px;
}


.villainfo h4 {
    text-transform: uppercase;
    font-family: 'Nuosu SIL', serif;
    font-size: 30px;
}


.villainfo p {
    font-family: var(---paraFont);
    font-size: 16px;
    color: gray;
}

.VillaImage {
    height: 40vh;
    box-shadow: 0 0 5px #808080;
    border-radius: 5px;
    margin-top: 20px;
    overflow: hidden;
}

.AboutVillaImages {
    margin: 20px;
}

.VillaImage img {
    height: 100%;
    width: 100%;
    border-radius: 5px;
    transition: all .5s ease-in-out;
}

.VillaImage:hover img {
    transform: scale(1.1);
}

.VillaImage:hover {
    transform: translateY(-5px);
    transition: transform .25s, -webkit-transform .25s;
}


@media(max-width:500px) {
    .AB-title h2 {
        font-size: 44px;
        top: 0;
    }
}

@media(max-width:430px) {
    .AboutUs-banner {
        background: linear-gradient(to right, rgb(0, 0, 0, .5), rgb(0, 0, 0, .6)), url("../greenHillVillas-webp/banner2.webp");
        height: 100vh;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
    }

    .AboutUs-banner-text h2 {
        font-size: 30px;
        color: white;
    }
    .AboutUs-banner-text h1 {
        font-size: 30px;
        color: white;
    }

    .ABS1-Text p {
        font-size: 15px;
        font-family: 'Courgette', cursive;
        color: white;
    }

    .ABS1-Text {
        margin-left: 50px;
    }

    .villainfo {
        padding-top: 10vh;
        margin: 0px;
        text-align: left;
    }

    .AboutUs-banner {
        height: 60vh;
    }

    .AboutUs-banner-text {
        height: 45vh;
    }

    .AB-title h1 {
        font-size: 40px;
    }

    .HS4-AboutUs-overlay-Image {
        height: 45vh;
        width: 40vh;
    }
    .HS4-AboutUs-Images {
        height: 45vh;
    }
}


.ContactUs-banner-text h1 {
    font-size: 30px;
    color: white;
}
.AboutUs-banner-text h1 {
    font-size: 50px;
    font-family: 'Lora', serif;
    font-weight: bold;
    color: white;
    margin: 0px;
    padding: 0px;
}
@media(max-width:700px){
    .AboutUs-banner-text h1{
        font-size: 30px;
    }
    .villainfo{
        padding-left: 8px;

    }
}
